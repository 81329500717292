<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile"/> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none"/> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <!-- <div
      class="slide slide-style-2Limit slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1"
    >
      <v-container>
        <v-row>
            <v-col cols="12">
                <h2 class="heading-title1 text-left font-weight-bold" style="font-size: 60px !important; line-height: 90px !important; letter-spacing: 2px !important;">
                    Datapipe
                </h2>
            </v-col>
            <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
                <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</h5>
            </v-col>
        </v-row>
        <div>

        </div>
      </v-container>

      <div class="photo-background">
        <span>
          <img src="../assets/images/bg/business-5475664.png" alt="" style="background-repeat: no-repeat; background-size: cover; background-position: center center;"/>
        </span>
      </div>
    </div> -->
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--1" style="padding-top:0px; padding-bottom:0px">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area rn-section-gap" id="blog">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Lorem ipsum dolor sit</h4>
              <h3 class="heading-title mb-5">
                Creation of Limit Management System (LMS), Wholesale Data Mart (WDM), and Retail Data Mart (RDM)
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">Which are new initiatives in <b style="font-weight: 700;">data architecture</b> in the financial sector, especially banking.</p>
            </div>
            <ServiceLimitManagementSystem/>
          </div>
        </v-container>
      </div>
    </div>  
    <!-- End Content -->

    <!-- Start Content -->
    <div class="rn-contact-area rn-section-gap bg_color--5" style="padding-top:0px; padding-bottom:0px" id="blog">
      <div class="contact-form--1">
        <v-container class="pa-0 d-flex justify-center" style="margin: auto;">
          <div class="section rn-blog-area rn-section-gap" > 
            <div class="section-title section-title--2 text-center mb--20">
              <div class="section-title section-title--2 text-center" style="margin-bottom: 80px">
              <!-- <h4 class="heading-title">little bold title</h4> -->
                <h3 class="heading-title mb-5">
                  Value Proposition
                </h3>
                <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              </div>
              <v-img contain width="1200" height="auto" src="../assets/images/featured/Value Proposition.png"/>
            </div>
          </div>
        </v-container>
      </div>
    </div>  
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
  import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
  import Contact from "../components/contact/Contact";
  import ServiceLimitManagementSystem from "../components/service/ServiceLimitManagementSystem.vue";
  import Footer from "../components/footer/Footer";
  export default {
    components: {
      HeaderOnePageTwo,
      HeaderOnePageTwoMobile,
      Contact,
      ServiceLimitManagementSystem,
      Footer,
    },
    data() {
      return {
        features: [
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/monitor.svg"),
            titleFeatures: "Map Based Flight Monitoring",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/info.svg"),
            titleFeatures: "Flight and Airport Information Panel",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/filter.svg"),
            titleFeatures: "Flight Search & Filtering",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/database.svg"),
            titleFeatures: "Real-time Data Source Processing",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/play.svg"),
            titleFeatures: "Playback Flights",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/bell.svg"),
            titleFeatures: "Custom Alert & Notification",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../assets/images/icons/pie-chart.svg"),
            titleFeatures: "Analytics & Reporting",
          },
        ]
      };
    },
  };
</script>
<style scoped>
.heading-title1 {
    color: #dd8732 !important;
    line-height: 60px;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-title2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-subtitle2 {
    color: #dd8732 !important;
    font-family: 'Urbanist', sans-serif !important;
}
.heading-caption {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.heading-caption2 {
    color: #f7f6f1 !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 16px !important;
    line-height: 25px;
    font-weight: 500;
}
.item-subtitle {
    color: #E18122 !important;
    font-family: 'Urbanist', sans-serif !important;
}
</style>